		/*** arabyte SCSS ****/

$col_acpblau: #0A2C51;
$col_acpverlauf: #0069b4;
$col_acpgrau: #E8E8E8;
$col_acpgrau_medium: #B1B1B1;
$col_acpgruen: #00EDD1;
$col_black: #051D36;
$padding_section_standard: 60px 0;
$padding_section_standard_x2: 120px 0 ;
$padding_section_minus: 120px 0 80px 0;
$padding_section_big: 150px 0 20px 0;
$padding_section_small: 20px;
$padding_section_all: 40px;
$padding_section_all_big: 60px;
$padding_section_all_small: 60px 60px 40px 60px;
$padding_text_small: 0 20px;
$padding_text_standard: 0 60px;
$txt_90: 90%;


/*** X Update 2021 ****/
a.searchwp-modal-form-trigger-el {
	height: 20px;
    position: relative;
    width: 20px;
    display: inline-block;
    z-index: 1000;
}
.fa-search {
	padding-left: 4px;
	}
.searchwp-modal-form__container {
	form {
		padding: 0 !important;
	}
	.input-group {
		margin-bottom: 0 !important;
	}
	[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    height: 2.4375rem;
    margin: 0 !important;
    padding: 1.5rem !important;
    border: none;
    border-bottom: none !important;
	}
}
.datebox {
	border-left: 1px solid #B4B4B4;
    padding-left: 20px;
	.pt20 {
		padding-top:20px;
	} 
}
form {
	padding: 20px;
    border: 2px solid $col_black;
	}
.x_bg_white {
	background: white;
	margin-top: 20px;
	padding: 20px;
}
.dropdown.menu > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    left: 0;
 
}
.dropdown.menu a {
    padding: 0;
}
.top-bar .dropdown.menu:first-child > li.is-dropdown-submenu-parent > a::after {
    display: block !important;
}
.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    
    border-color: $col_acpblau transparent transparent;
}
.dropdown .is-dropdown-submenu a {
    padding: 6px 15px 6px 18px !important;
	line-height: 1.3;
	font-size: 1.05rem;
	}
.dropdown .is-dropdown-submenu li {
    padding: 0  !important;

}
.dropdown .is-dropdown-submenu {
    padding: 34px 0 15px 0 !important;
}
.is-dropdown-submenu {
	min-width: 220px;
}
#x_header {
	padding: 20px 0 0 0;
	ul.x_menu {
	 
		list-style: none;
		/**padding: 16px 0 0 0;**/ 
		float: right;
		li {
			display: inline;
			color: $col_acpblau;
			font-weight: 400;
			font-size: 1.2rem;
			padding: 10px 8px;
		}
	}
	.btn_x_menu {
		border: 2px solid $col_acpblau;
		padding: 10px 15px 10px 10px !important;
		margin-left: 10px;
		text-align: center !important;
		 
		 @include breakpoint(large) {
		 
			}
	}
	.lang-item {
		margin-left: 60px !important;
		padding: 10px 15px 10px 10px !important;
		text-align: center !important;
		border: 2px solid $col_acpblau;
	}
	.langswitch {
		padding: 0;
		list-style: none;
		    position: absolute;
			right: 68px;
		bottom: -2px;
		.lang-item {
		margin-left: 0 !important;
		padding: 8px 12px 7px 10px !important;
		display: inline-block;
		border: 2px solid $col_acpblau;
		}
	}
}
.vertical .btn_x_menu {
 
		display: none !important;
		 @include breakpoint(large) {
			 display: inline;
			}
	}
.x_content {
	padding-top: 160px; 
	h2 {
		color: $col_acpblau;
		font-weight: bold;
	}
	p {
		color: $col_acpblau;
		padding-right: 60px;
		padding-top: 30px;
		font-family: $body-font-family;
	}
	h3 {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
}
.x_txtbox_wrapper {
	position: relative;
    height: 100%;
    margin: 0 auto;
    max-width: 86rem;
	.txtbox_right {
		position: absolute;
		right: 0;
		bottom: 0;
		background-color: rgba(10, 44, 81, 0.75);
		color: white;
		padding: 30px 70px 30px 50px;
		@include breakpoint(medium) {
			bottom: 70px;
			}
	}
	h1 {
		font-size: 1.75rem;
		font-weight: 400;
		@include breakpoint(medium) {
			font-size: 2.5rem;
			}
		@include breakpoint(large) {
			font-size: 5.5rem;
			}
	}
	span {
		font-weight: bold;
		font-size: 1.35rem;
	}
	.x_btn_black {
	position: absolute;
    right: -10px;
    width: 70px;
    bottom: 110px;
    background: black;
    color: white;
    padding: 15px 0 15px 25px;
    text-align: center;
    font-size: 2rem;
		a {
			color: white;
		}
		img {
			width: 20px !important;
		}
	}
}
.x_content_txtbox_wrapper {
	position: relative;
    height: 100%;
  
	.txtbox_right {
		/*position: absolute;
		right: 0;
		bottom: 0;
		min-width: 460px;*/
		 background-color: rgba(10, 44, 81, 0.75);
		color: white;
		position: absolute;
		bottom: 0;
		margin: 0 auto;
		width: 100%;
		padding: 15px 16px 14px ;
		 @include breakpoint(large) {
			 padding: 18px 0 12px 0;
			}
	}
	h1 {
		 font-size: 1.95rem;
		
		font-weight: 400;
		margin-bottom: 0;
		 @include breakpoint(large) {
			font-size: 2.35rem;
			}
	}
	a {
		color: white;
		font-weight: bold;
		&:hover {
			opacity: .5;
		}
	}
	span {
		font-weight: normal;
		font-size: 1.05rem;
 
	}
	.x_btn_black {
	position: absolute;
    right: -10px;
    width: 70px;
    bottom: 110px;
    background: black;
    color: white;
    padding: 15px 0 15px 25px;
    text-align: center;
    font-size: 2rem;
		a {
			color: white;
		}
		img {
			width: 20px !important;
		}
	}
}
.x_btn_black-reg {
     width: 90px;
    float: right;
    margin-top: 65px;
    background: black;
    color: white;
    padding: 18px 0 18px 4px;
    text-align: center;
    font-size: 2rem;
		a {
			color: white;
		}
		img {
			width: 20px !important;
		}
	}
.x_btn_white {
	display: inline-block;
	padding: 15px 30px;
	font-size: 1.125rem;
	font-weight: bold;
	color: $col_acpblau;
	background: white;
	margin-top: 30px;
	@include breakpoint(large) {
		font-size: 1.75rem;
		margin-top: 80px;
			}
}
.x_btn_white-abso {
	display: inline-block;
    position: absolute;
    padding: 10px 18px;
        float: right;
    top: 80px;
    right: -20px;
    font-size: 1rem;
    font-weight: bold;
    color: #0A2C51;
    background: white;
	img {
		    display: inline !important;
    width: 10px !important;
    padding-bottom: 2px;
    margin-right: 5px;
	}
	 @include breakpoint(large) {
		 font-size: 1.15rem;
		 top: 100px;
		   padding: 15px 30px;
			}
}
.slide-progress {
  width: 0;
  max-width: 100%;
  height: 4px;
  background: $col_acpblau;
	    position: relative;
		}
.slider-counter {
	z-index: 1000;
	margin-left: 30px;
	font-size: 1rem;
	margin-top: -30px;
    position: relative;
    font-size: .85rem;
    color: white;
	@include breakpoint(large) {
			 margin-left: 50px;
			}
	 @include breakpoint(xlarge) {
			}
}
.x_white {
	background: white;
	color: #051D36;
	padding: 40px 16px;
	@include breakpoint(large) {
			 padding: 100px 0;
			}
	h2 {
		margin-top: 0;
		color: $col_acpblau;
		font-weight: bold;
	}
	h3, h4 {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
	p {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
}
.x_white_small {
		background: white;
	color: #051D36;
		padding: 40px 16px 60px;
	@include breakpoint(large) {
			 padding: 100px 0 ;
			}
	h2 {
		margin-top: 0;
		color: $col_acpblau;
		font-weight: bold;
	}
	h3, h4 {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
	p {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
}
.x_white_small_notop {
		background: white;
	color: #051D36;
	padding: 0 0 40px 0;
	h2 {
		margin-top: 0;
		color: $col_acpblau;
		font-weight: bold;
	}
	h3, h4 {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
	p {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
}
.x_white_search {
		background: white;
	color: #051D36;
	padding: 40px 0 50px 0;
	h2 {
		margin-top: 0;
		color: $col_acpblau;
		font-weight: bold;
	}
	h3, h4 {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
	p {
		color: $col_acpblau;
		font-family: $body-font-family;
	}
}
.x_grey {
	background: $col_acpgrau;
	color: #051D36;
	padding: 40px 16px;
	@include breakpoint(large) {
			 padding: 100px 0;
			}
	h2 {
		margin-top: 0;
		color: $col_black;
		font-weight: bold;
	}
	h3, h4  {
		color: $col_black;
		font-family: $body-font-family;
	}
	p {
		color: $col_black;
		font-family: $body-font-family;

	}
}
.x_blue {
	background: #274b73; /* Old browsers */
	background: -moz-linear-gradient(-45deg,  #274b73 17%, #0a2c51 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg,  #274b73 17%,#0a2c51 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg,  #274b73 17%,#0a2c51 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#274b73', endColorstr='#0a2c51',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		padding: 40px 16px;
	@include breakpoint(large) {
			 padding: 100px 0;
			}
	color: white;
	h2 {
		margin-top: 0;
		color: white;
		font-weight: bold;
	}
	h3, h4  {
		color: white;
		font-family: $body-font-family;
	}
	p {
		color: white;
		font-family: $body-font-family;
	}
	.blue_border-left {
		border-bottom: 1px solid #506883;
		padding-bottom: 20px;
		@include breakpoint(large) {
					border-left: 1px solid #506883;
					padding-left: 20px;
					padding-bottom: 0;
					border-bottom: none;
				}
	}
	.x_image {
    padding: 10px 0;
	max-width: 170px;
		@include breakpoint(medium) {
					 padding: 10px 0;
					max-width: 220px;
				}
	}
}
.x_more_img_box {
	background-size: cover;
	height: 260px;
	width: 100%;
	margin-bottom: 30px;
	margin-top: 30px;
	position: relative;
 
	transition: all ease .3s;
	@include breakpoint(large) {
					margin-top: 0;
				}
	figure {
	  position: absolute;
    margin: 0;
    top: -15px;
    left: -15px;
    width: 70px;
	}
	&:hover {
		 opacity: .6;
		 
	}
}
.x_image {
	padding: 20px 0;
}
.x_subtitle {
	padding: 20px 0;
}
.x_intro_subtitel {
	max-width: 70%;
	margin: 0 auto;
	padding: 10px 0 30px 0;
}
#x_white{
	background: white;
	text-align: center;
	color: #051D36;
 	padding: 20px 16px;
	@include breakpoint(large) {
			 	margin: 60px 0 40px 0;
			}
		span {
			font-size: 1.1rem;
			color: #9E9E9E;
				@include breakpoint(small only) {
					display: block;
				}
			}
		p {	margin-bottom: 0;}
}
#x_grey {
	background: $col_acpgrau;
	text-align: center;
	color: #051D36;
	padding: 25px 16px 0;
	@include breakpoint(medium) {
					margin: 0 0 60px 0;
						padding: 25px 0;
				}
		span {
			font-size: 1.1rem;
			color: #9E9E9E;
				@include breakpoint(small only) {
					display: block;
				}
			}
		p {	margin-bottom: 0; font-family: $body-font-family;}
}
#x_grey_small {
	background: $col_acpgrau;
	color: #051D36;
	padding: 40px 16px 80px;
	@include breakpoint(large) {
			 padding: 80px 0 60px 0;
			}
	h3 {
		font-weight: bold;
	}
		p {	font-family: $body-font-family;}
		span {
			font-size: 1.1rem;
			color: #9E9E9E;
				@include breakpoint(small only) {
					display: block;
				}
			}
	 
}
.x_samples {
	h4 {
		font-weight: bold;
		padding-top: 20px;
	}
}
#x_grey_logos {
	background: $col_acpgrau;
	text-align: center;
	color: #051D36;
	padding: 25px 0;
	margin: 80px 0 0 0;
		span {
			font-size: 1.1rem;
			color: #9E9E9E;
				@include breakpoint(small only) {
					display: block;
				}
			}
		p {	margin-bottom: 0;}
}
#news_x_landing {
	color: $col_acpblau;
	 	padding: 60px 16px 20px ;
	text-align: left;
	@include breakpoint(medium) {
					padding: 60px 16px 30px ;
				}
	.text-right {
		@include breakpoint(small only) {
					text-align: left;
				}
	}
	@include breakpoint(medium) {
		 
				}
	h3 {
		font-weight: bold;
		text-align: left !important;
	}
	p {
		color: $col_acpblau;
		font-family: $body-font-family;
		text-align: left !important;
	}
	.custom-pagination {
		span {
			display: inline !important;
		}
		@include breakpoint(small only) {
					margin-bottom: 20px;
				}
	}
}
.x_searchwrap {
	background: $col_acpgrau;
	color: $col_acpblau;
	margin-bottom: 20px;
	a {
		color: $col_acpblau !important;
		display: block;
	}
}
.x_newswrap {
	background: $col_acpgrau;
	color: $col_acpblau;
	 margin: 15px 0;
	@include breakpoint(large ) {
					    margin:  0;
				}	
	a {
		color: $col_acpblau !important;
		display: block;
	}
}
.x_cardlink_landing {
	font-weight: bold;
	padding: 0 20px 20px 20px;
}
.x_newspadtext {
	padding: 10px 20px 20px 20px;
}
.x_newspadtext_small {
	padding: 20px 20px 0 20px;
	font-size: .9rem;
}
.x-title {
    font-size: 1.3rem;
    font-weight: 400;
	line-height: 1.4;

}
.x_content_pad_landing {
	margin: 30px 0 0 0;
	padding: 0;
	.cell {
	padding: 2px;
		@include breakpoint(large) {
		margin: 40px 0;
		padding: 20px;
		}
	}

}
#x_cta_footer {
	h4 {
		font-family: $body-font-family;
		@include breakpoint(medium only) {
					 font-size: 2.5rem;
				}
	}
	span {
		display: block;
		color: white;
		padding-bottom: 30px;
	}
	@include breakpoint(small only) {
					.text-center {
						text-align: left;
					}
				}
				@include breakpoint(medium only) {
					.text-center {
						text-align: left;
					}
				}
}

	
.txt_x_content_landing {
		    position: absolute;
			bottom: 65px;
			padding-right: 30px;
			padding-left: 30px;
			@include breakpoint(medium) {
					bottom: 80px;
				
				}
			@include breakpoint(large) {
					bottom:110px;
						padding-right: 0;
				}	
			 
		span {
				display: block;
				opacity: .7;
				font-size: .8rem;
				padding-bottom: 20px;
				padding-top: 10px;
				@include breakpoint(medium) {
						font-size: 1.25rem;
						padding-bottom: 0;
						padding-top: 0;
				}
				@include breakpoint(large) {
						font-size: 1.25rem;
				}
			}
 	 
	
}
#x_foot {
	color: $col_acpblau;
	padding: 60px 16px;
	@include breakpoint(large) {
			 padding: 120px 0 65px 0;
			}
	a {
		color: $col_acpblau;
		&:hover {
 					color: #1779ba;
 		}
	}
	p {
		font-weight: normal;
		font-size: 1.1rem;
	}
}
.foot_logo {
	width: 240px;
	padding-bottom: 30px;
	}
.smaller_p {
	font-size: 1rem !important;
}
#x_logos_big {
	padding: 20px 0 100px 0;
	h4 {
		color: $col_acpblau;
		font-weight: bold;
		text-align: center;
		}
}
.border-left {
	padding-top: 80px !important;
	padding-left: 30px !important;
	border-left: 1px solid $col_acpgrau;
}
img.partner {
	background: white;
	padding: 20px 8px;
}
.partner_box {
	    background: white;
    margin: 0 20px 20px 0;
    padding: 20px;
}
g#id-512 {
	fill: #0A2C51 !important;

}
g#id-521 {
	fill: #0A2C51 !important;
}
/*** Navigation ***/

.submenu-toggle {
    top: 4px  !important;
}
 

.off-canvas  {
	.is-accordion-submenu-parent  {
		a {
    padding: 18px 15px !important;
	}
	}
}
.off-canvas  {
	.menu-item-object-page  {
		a {
    padding: 18px 15px !important;
	}
	}
}

.off-canvas  {
	.is-submenu-item  {
		a {
    padding: 10px 15px !important;
	    opacity: .7;
	}
	}
}




.prenav {
	display: block;
	background: #f5f5f5;
    color: #2a2a2a;
    font-size: 13px;
    font-weight: 700;
    text-align: right;
    padding: 8px 20px 8px 0;
	a {
		 color: #2a2a2a;
		 transition: all ease .25s;
		 padding: 0 0 0 20px;
		 &:hover {
			 opacity: .6;
		 }
	}
	.lang-item {
		display: inline;
	}
	@include breakpoint(medium) {
			display: block;
	}
}
.top-bar-right {
 
}
.title-bar .menu-icon {
    top: 12px;
	@include breakpoint(medium) {
			top: 22px;
			}
}

.back-to-top {
	background: rgba(0,0,0,.6);
	padding: 3px 0 10px 12px;
	color: white;
	height: 40px;
	width: 40px;
	z-index: 10000;
	position: fixed;
	bottom: 20px;
	right: 20px;
	i {
		font-size: .9rem;
		color: white;
	}
}
.sticky-container {

}
.footsubnav {
		margin-left: 0;
    list-style: none;
	 @include breakpoint(small only) {
			margin-top: 25px;
			}
		li {
			line-height: 1.5;
			a {
				font-weight: bold;
				font-size: 1.1rem;
				padding-bottom: 18px;
				display: block;
				transition: all ease .3s;
				&:hover {
					color: #1779ba;
					 .img_padright {
					padding-right: 15px;
						}
					}
			}
		}
	 .img_padright {
		 padding-right: 5px;
		 transition: all ease .3s;
	 }
}
.img_zert {
 width: 125px;
 padding-bottom: 10px;
 @include breakpoint(medium) {
			float: right;
			padding-bottom: 0;
			}
}
.logo_acp_footer {
		width: 210px;
		margin-top: 0;
		display: block;
		padding-bottom: 30px;		
		@include breakpoint(large) {
				padding-bottom: 0;
			}
		}
.logo_acp {
		width: 180px;
		margin-top: 0; 
		padding-left: 0;
		transition: all ease .25s;
		@include breakpoint(medium) {
		padding-left: 0px;		
		width: 250px;
		margin-top: 0;
		}
		}
.scrollnav {
	z-index: 20;
	height: 65px;
	/*padding-top: 20px;*/
	-webkit-transition: height .25s ease;
	transition: height .25s ease;
	background: white;
	-webkit-transition: .25s ease;
	transition: .25s ease;
	transition: .25s ease; 
	/*-webkit-box-shadow: 0px 7px 10px -7px rgba(0,0,0,0.23);
	-moz-box-shadow: 0px 7px 10px -7px rgba(0,0,0,0.23);
	box-shadow: 0px 7px 10px -7px rgba(0,0,0,0.23); */
	@include breakpoint(large) {
			height: 100px;

	}
	}
	.scrollnav a {
		color: black; 
	}
	.scrollnav.shrink .menu > li > a::after {
		margin-right: -6px;
	}
 
	.scrollnav.shrink {
		padding-top: 0;

		background-color: white;
		z-index: 20;
	}
 @media screen and (min-width: 1341px) {
	 .scrollnav.shrink {
		padding-top: 10px;
		height: 75px;
		background-color: white;
		z-index: 20;
	}
 }
  @media (min-width: 641px) and (max-width: 1340px) {
    .scrollnav.shrink {
      /*padding: 0.2rem 0 0.2rem 0 !important;*/
      height: 65px !important; } 
	  }
	.scrollnav.shrink .head_title {
		display: none; }
	.scrollnav.shrink .top-bar-title {
		font-size: 1.2rem; }
	.scrollnav.shrink .top-bar-title .logo_acp {
		width: 150px !important;
		margin-top: 0; }
    .scrollnav.shrink .menu > li > a {
		-webkit-transition: padding-top .25s ease;
		transition: padding-top .25s ease;
		
		-webkit-transition: .25s ease;
		transition: .25s ease; }
    @media screen and (max-width: 1340px) {
    .scrollnav.shrink .menu > li > a {
		font-size: 1rem !important; } 
	}
	.scrollnav.shrink .scrollnav-right, .scrollnav.shrink .scrollnav-left {
		  
		}
	.top-bar {
		background: none;
		
	}

/**** Verlauf ***/

@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }
  
  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}


@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  
  @return $is-direction or $is-angle;
}

@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }
  
  $conversion-map: ( 
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );
    
  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }
  
  @return 90deg - convert-angle($value, 'deg');
}


@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }
  
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

// Tests

.verlauftest {
  @include linear-gradient(50deg,   #00ACEA 0%,  #00EDD1 100%);
  /*  @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%); */
  /*  @include linear-gradient(#31B7D7, #EDAC7D); */
  /*  @include linear-gradient(to right, #31B7D7, #EDAC7D); */
}

/*** Landingpage ***/

.landing_wrapper_owl {
	background: $col_acpblau;
	position: relative;
	height: 300px;
	color: white;
	@include breakpoint(medium only) {
			margin-top: 60px;
		}
	@include breakpoint(large) {
			height: 500px;
		}
	.txtbox_wrapper {
		height: 60px;
	}
	span {
		display: block;
		padding-top: 18px;
		font-weight: 300;
		font-size: 1rem;
	}
	.image_slider_bg {
		height: 300px;
		background-size: cover;
		background-position: center;
		@include breakpoint(large) {
			height: 500px;
		}
	}
}
.landing_wrapper_owl_outter {
	background: $col_acpblau;
	height: 320px;
	width: 100%;
	position: absolute;
	left: -40px;
	margin-right: 40px;
	top: 40px;
	@include breakpoint(large) {
			height: 520px;
		}
}

/* NEWS UPDATE */
.paddcase {
	padding-bottom: 40px;
}
.custom-pagination {
	margin-top: 20px;
	@include breakpoint(small only) {
			padding-bottom: 20px;
			padding-top: 20px;
			text-align: left;
		}
}
.page-numbers {
	padding: 6px 12px;
    background: $col_acpgrau;
    margin-right: 5px;
    color: black;
	transition: all ease .3s;
	&:hover {
		color: white;
		 background: $col_acpblau !important;
		 opacity: 0.8;
	}
}
.page-numbers.current{
		 color: white;
		 background: $col_acpblau !important;
	}

.postlink {
	a {
		color: black;
		opacity: 0.8;
		transition: all ease .3s;
		&:hover {
			opacity: 1;
		}
		
	}
	@include breakpoint(small only) {
			margin-bottom: 30px;
			display: block;
			font-size: .9rem;
		}
}
.related-posts {
	h4 {
		a {
			color: black;
		}
	}
	@include breakpoint(small only) {
			padding-left: 10px;
		}
}

#news {
	padding: $padding_section_standard;
}
.cardlink_landing {
	padding: 0 20px 20px 20px;
	color: $col_acpblau;
	font-size: .9rem;
	display: block;
}
#news_landing {
	padding: $padding_section_standard;
	background: $col_acpgrau;
	h4 {
		font-size: 2rem;
		font-weight: bold;
	}
	span.entry-title {
 
	font-family: $header-font-family;
	font-weight: bold;
	display: block;
	padding-bottom: 20px;
	padding-right: 20px;
	transition: all ease .25s;
	a {
		color: #292929;
		&:hover {
			color: $col_acpverlauf;
		}
	}
	}
}
#news_padding {
	padding-left: 10px;
	@include breakpoint(medium) {
			padding-left: 40px;
		}
}
.newspadtext {
	padding: 20px 0 0 20px;
}
.wrapbtncenter {
	position:  relative;
	width: 100%;
	height: auto;
	text-align: center;
	margin-top: 30px;
	@include breakpoint(large) {
		 margin-top: 0;
		}
}
.whitewrap {
	background: white;
	-webkit-box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.76);
-moz-box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.76);
box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.76);
    margin-top: 30px;
		@include breakpoint(large) {
		 margin-top: 0;
		}
}
.projektimage {
	height: 240px;
    background-size: cover;
	background-position: center;
	width: 100%;
 	position: relative;
	transition: all 0.4s ease;
	margin: 0 auto 14px auto;
	@include breakpoint(large) {
		background-size: 130%;
		margin-top: 45px;
	}
	&:hover {
		@include breakpoint(large) {
		background-size: 110%;
		}
		}
 
}
.projektimage_ref {
	height: 220px;
    background-size: cover;
	background-position: center;
	width: 100%;
 	position: relative;
	transition: all 0.4s ease;
	margin: 0 auto 14px auto;
	@include breakpoint(medium) {
		height: 160px;
	}
	@include breakpoint(large) {
		background-size: 140%;
		margin-top: 45px;
		height: 285px;
	}
	&:hover {
		@include breakpoint(large) {
		background-size: 110%;
		}
		}
 
}
.btn_refs {
	color:#051D36;
	font-size: .8rem;
	font-weight: bold;
}
.more_btn {
		@include breakpoint(small only) {
		margin-bottom: 20px;
		}
}

h3.ref_title 
	 {
		font-family: $body-font-family;
		font-size: 1.1rem;
		padding: 10px 20px 10px 0;
			@include breakpoint(medium) {
				font-size: .9rem;
				padding: 15px 20px 15px 0;
			}
			@include breakpoint(large) {
				font-size: 1.4rem;
				padding: 25px 20px 25px 0;
			}
		a {
		color: #051D36 !important;
		}
	}
p.ref_intro {
	font-size: .9rem;
	padding-right: 20px;
}
 .txt_wrapper_landing {
    color: #fff;
    position: relative;
    height: 100%;
    margin: 0 auto;
    max-width: 86rem;
	.btn_news {
		&:hover {
			a  {
				color: #3A3A3A;
				}
			}
		a {
			color: white;
			
		}
	}
 }
	
.txt_content_landing {
		    position: absolute;
			bottom: 50px;
			padding-right: 30px;
			padding-left: 30px;
			@include breakpoint(medium) {
					bottom: 80px;
					padding-right: 0;
				}
			@include breakpoint(large) {
					bottom: 60px;
				}	
			 
		span {
				display: block;
				opacity: .7;
				font-size: .8rem;
				padding-bottom: 20px;
				padding-top: 10px;
				@include breakpoint(medium) {
						font-size: 1.25rem;
						padding-bottom: 0;
						padding-top: 0;
				}
				@include breakpoint(large) {
						font-size: 1.25rem;
				}
			}
 		h1 {
		    font-size: 1.35rem;
			max-width: 380px;
			line-height: 1.2;
				@include breakpoint(medium) {
					font-size: 2.35rem;
					padding-left: 0;
					max-width: 680px;
					line-height: 1.2;
				}
				@include breakpoint(large) {
					    font-size: 2.75em !important;
						max-width: 900px;
						line-height: 1.2;
				}
			}
	
}

.txt_wrapper {
	color: white;
	position: relative;
	height: 100%;
	.txt_content {
		 
		    position: absolute;
			bottom: 30px;
			width: 100%;
			z-index: 100;
			
			@include breakpoint(medium) {
			  bottom: 60px;
			    padding-left: 40px;
			}
				@include breakpoint(large) {
			  bottom: 100px;
			  padding-left: 40px;
				}
			 
	}
	h1 {
		    font-size: 1.5rem;
			padding-left: 20px;
		@include breakpoint(medium) {
			   font-size: 2rem;
			   padding-left: 0;
			}
		@include breakpoint(large) {
			   font-size: 3rem;
			}
		}
}
#kundenstimmen {

	background-size: cover;
	background-position: center;
	text-align: center;
	padding: $padding_section_standard;
	@include breakpoint(large) {
			padding: $padding_section_standard_x2;
		}
	.kundenstimmen_box {
		position: relative;
		margin: 0 auto;
		padding:30px 40px;
		width: 95%;
		background: white;
		@include breakpoint(large) {
			width: 65%;
			padding: $padding_section_all_small;
		}
		p {
			font-size: 1rem;
			font-weight: 300;
			@include breakpoint(large) {
			font-size: 1.4rem;
		}
		}
		span {
			display: block;
			padding-top: 15px;
			font-size: .9rem;
			font-style: italic;
		}
		h4 {
			font-weight: bold;
			text-transform: uppercase;
			font-size: 1.125rem;
			padding-bottom: 20px;
 
		}
	}
}
#kundenstimmen_update {

	background-size: cover;
	background-position: center;
	text-align: center;
	color: white;
	padding: $padding_section_standard;
	@include breakpoint(large) {
			padding: $padding_section_standard_x2;
		}
	.kundenstimmen_box {
		position: relative;
		margin: 0 auto;
		padding:30px 20px;
		width: 95%;
		background: none;
		@include breakpoint(large) {
			width: 65%;
			padding: 10px 60px 40px;
		}
		p {
			font-size: 1rem;
			font-weight: 300;
			@include breakpoint(large) {
			font-size: 1.4rem;
		}
		}
		span {
			display: block;
			padding-top: 15px;
			font-size: .9rem;
			font-style: italic;
		}
		h4 {
			font-weight: bold;
			text-transform: uppercase;
			font-size: 1.425rem;
			padding-bottom: 50px;
			opacity: .5;
 
		}
	}
}
/*** Start Sidenavi ****/
.sidenav_acp {
	position: fixed;
	right: -4px;
	top: 30%;
	/**z-index: 100;***/
	.searchwp-modal-form-trigger-el {
	position: relative;
    z-index: 100;
   padding: 15px 14px;
		@include breakpoint(medium) {
			 padding: 20px 22px;
		}
	}
	.fa-search	{
	padding: 0 !important;
    position: absolute;
    left: 14px;
    margin-top: 12px;
    z-index: 1;
	@include breakpoint(medium) {
			  left: 22px;
			margin-top: 12px;
		}
	}
	@include breakpoint(medium) {
			right: 0;
		}
	.has-tip {
    position: relative;
    display: inline-block;
	cursor: pointer;
	border-bottom: none; 
	}
	.has-tip:focus {
    border: none;
	outline: none;
	}
	.has-tip:active {
    border: none !important;
	outline: none;
	
	}
	ul {
		list-style: none;
		margin: 0;
		border-radius: 0 ;
		box-shadow: 4px 4px 8px rgba(0, 0, 0, .3);
			li {
				
			a {
				color: white;
				
				&:active {
					border: none !important;
					outline: none;
				}
				&:focus {
					border: none !important;
					outline: none;
				}
			}
			i {
					 padding: 15px 14px;
				font-size: .75rem;   
					@include breakpoint(medium) {
					 padding: 20px 22px;
				font-size: 1.2rem;
				font-weight: 200;
					}		
							&:hover {
					}
			}
			color: white;
			
			transition: all ease .35s;
			background: $col_acpblau;
				&:hover {
				background: #051d36;
		 	}
			
		
		}
	}
}

/*** ENDE Sidenavi ***/
#precontent_link {
		background: $col_acpgrau;
		text-align: center;
		color: #051D36;
		padding: 25px 0;
		span {
			font-size: 1.3rem;
			font-weight: bold;
			@include breakpoint(small only) {
				display: block;
			}
		}
		p {
			margin-bottom: 0;
		}
		a {
			display: inline-block;
			border: 2px solid #3A3A3A;
			padding: 6px 20px;
			color: #3A3A3A;
			margin-left: 30px;
			font-size: 1rem;
			font-weight: bold;
			font-family: $header-font-family;
			transition: all ease .25s;
				@include breakpoint(small only) {
				margin-left: 0;
				margin-top: 10px;
			}
			&:hover {
				background: #3A3A3A;
				color: white;
			}
			  
		}
}
#prefoot_link {
		background: #0A2C51;
		text-align: center;
		color: white;
		padding: 25px 0;
		span {
			font-size: 1.3rem;
			@include breakpoint(small only) {
				display: block;
			}
		}
		p {
			margin-bottom: 0;
		}
		a {
			display: inline-block;
			border: 1px solid white;
			padding: 6px 20px;
			color: white;
			margin-left: 30px;
			font-size: .9rem;
			transition: all ease .25s;
			&:hover {
			color: white;
			background: #051d36;
			border: 1px solid #051d36;
			}
				@include breakpoint(small only) {
				margin-left: 0;
				margin-top: 10px;
			}
			  
		}
}

#news {
	.produkte_box {
		background: $col_acpblau;	
		color: white;
		position: relative;
		padding: 20px;
		margin-top: 60px;
		@include breakpoint(medium) {
			padding: $padding_section_all;
		}
		@include breakpoint(large) {
			padding: $padding_section_all;
			top: -160px;
			margin-bottom: -100px;
		}
		h4 {
			font-weight: bold;
			padding-bottom: 30px;
			span {
				display: block;
				font-weight: 400;
			}
		}
		figure {
			height: 60px;
			padding: 12px 0;
			img {
			width: 40px;
			float: left;
			
			}
			span {
			font-family: $header-font-family;
			font-weight: bold;
			font-size: 1.6rem;	
			float: left;
			padding: 5px 0 0 20px;
			}
		}
		p {
			padding-top: 30px;
		}
		a {
			color: white;
			transition: all ease .25s;
			text-decoration: underline;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}

#branchenloesung {
	background: $col_acpgrau;
	padding: 120px 5px 60px 5px;
	margin-top: 0;
	@include breakpoint(large) {
		margin-top: -60px;
		padding: $padding_section_minus;
	}
	.branchen_item {
		float: left;
		width: 48%;
		position: relative;
		margin: 1%;
		height: 190px;
		overflow: hidden;
		background-size: 170%;
		background-position: bottom;
		transition: all ease .25s;
		/*@include linear-gradient(50deg,   #00ACEA 0%,  #00EDD1 100%);*/
		@include breakpoint(medium) {
			width: 30%;
			margin: 1%;
			}
			&:hover {
				background-size: 200%;
			}
		span {
			display: block;
			padding: 85px 5px;
			color: white;
			font-size: 0.9rem;
			width: 100%;
			margin: 0 auto;
			text-align: center;
		}
		.bg_verlauf {
			width: 100%;
			bottom: 0;
			position: absolute;
			height: 190px;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
		}
		.bg_darken {
			width: 100%;
			bottom: 0;
			position: absolute;
			height: 190px;
			background: rgba(0,0,0, 0.35);
	
		}
	}
}


#logos {

	padding: 0;
	margin: 0 0 60px 0;
	@include breakpoint(medium) {
	margin: 30px 0 60px 0;	
	}
	h2 {
		padding-bottom: 30px;
	}
	.logo_item {
		float: left;
		width: 48%;
		position: relative;
		margin: 1%;
		transition: all ease .25s;
		/*@include linear-gradient(50deg,   #00ACEA 0%,  #00EDD1 100%);*/
		@include breakpoint(medium) {
			width: 25%;
			margin: 0;
			}
		@include breakpoint(large) {
			width: 20%;
			margin: 0;
			}
			&:hover {
				background-size: 200%;
			}
	}
}



/*** Content ***/
#breadcrumbs_update {
	text-align: center;
	font-family: $header-font-family;
	a {
	color: #292929;
	}
	
}
h1.sitetitle {
	font-size: 2rem;
	font-weight: 500;
	line-height: 1.1;
	span {
		display: block;
		font-weight: 400;
		margin-top: 20px;
		font-size: 1.3rem;
	}
	@media only screen and (max-width: 1024px) and (min-width: 640px) {
			font-size: 1.8rem !important;
			span {
		line-height: 1.5;
	}
	}
	@media only screen and (max-width: 1380px) and (min-width: 1025px) {
		font-size: 3rem !important;
	}
	@include breakpoint(large) {
		font-size: 4rem;
	}
}
section {
	h2 {
		margin-top: 40px;
	}
}
.txt_big {
	font-size: 1.45rem;
	display: block;
		width: $txt_90;
}
.padding-txt {
		padding: 10px;
		@include breakpoint(large) {
			padding: $padding_text_standard;
		}
}
.padding-small {
		
		@include breakpoint(large) {
					padding: $padding_section_standard;
		}
}
.padding_big {
	padding: 60px 0 20px 0;
	@include breakpoint(medium) {
	padding: 80px 0 20px 0;
	}
	@include breakpoint(large) {
	padding: $padding_section_big;
	}
}
.content_pad {
	margin: 40px 0;
	.cell {
		padding: 0 0 30px 0;
			@include breakpoint(medium) {
			padding: 20px;
			}
	}
	h3 {
		font-family: $body-font-family;
		padding: 25px 0 5px 0;
		color: $col_acpblau;
		font-size: 1.4rem;
		@include breakpoint(medium) {
		padding: 30px 0 15px 0;
			}
		span {
			display: block;
			font-family: $header-font-family;
			font-size: 1.125rem;
			color: #292929;
		}	
	}
	p {
		font-size: .89rem;
		line-height: 1.55;
		@include breakpoint(medium) {
			font-size: .9125rem;
		line-height: 1.75;
			}
	}
}

.content_pad_landing {
	margin: 40px 0;
	padding: 0;
	@include breakpoint(large) {
			 
			}
	.cell {
		 padding: 2px;
			@include breakpoint(large) {
			padding: 20px;
			}
	}
	h3 {
			font-family: $body-font-family;
			font-size: 1.4rem;
			position: relative;
			text-align: center;
			top: -90px;
			color: white;
			margin-bottom: -30px;
			@include breakpoint(xlarge) {
				top: -160px;
				font-size: 1.5rem;
			}
	 
		span {
			display: block;
			font-family: $header-font-family;
			font-size: 1rem;
			color: white;
			opacity: .7;
			z-index: 10000;
		}	
	}
	p {
		font-size: .89rem;
		line-height: 1.55;
		@include breakpoint(medium) {
			font-size: .9125rem;
			line-height: 1.75;
			}
	}
	img {
		transition: all ease .25s;
		&:hover {
			-webkit-transform: scale(1.1);
-moz-transform: scale(1.1);
-ms-transform: scale(1.1);
-o-transform: scale(1.1);
transform: scale(1.1);
		}
	}
}


  
/**** News ****/
.nav-links {
	display: block;
	width: 100%;
	.nav-previous {
		float: none;
		border-top: 1px solid #dadada;
		padding: 10px 0;
 
	}
	.nav-next {
		float: none;
		border-top: 1px solid #dadada;
		padding: 10px 0;
	 
	}
	a {
			color: black;
	}
}
#news {
	padding-left: 15px;
	padding-right: 15px;
}
.box_prenav {
	width: 32%;
	margin-right: 1%;
	display: inline-block;
	padding: 20px;
	background: $col_acpgrau;
	border-radius: 5px;
}
h4.entry-title {
	span {
		display: block;
		font-size: 1.2rem;
		font-family: $body-font-family;
	}
}
span.entry-title {
	font-size: 1.3rem;
	font-family: $header-font-family;
	font-weight: bold;
	display: block;
	padding-bottom: 20px;
	a {
		color: #292929;
		&:hover {
			color: $col_acpverlauf;
		}
	}
}
span.time {
	font-size: .925rem;
	font-family: $header-font-family;
	font-weight: 300;
	display: block;
}
span.auszug {
	font-size: 1.1rem;
}
.newspadding {
	padding-right: 10px ;
	@include breakpoint(medium) {
		padding-right: 50px ;
	}
}
a.linknews {
	color: #707070;
	display: block;
	margin-bottom: 20px;
	&:before {
		content: "";
		width: 30px;
		position: relative;
		display: inline-block;
		border-bottom: 1px solid #707070;
		top: -4px;
		margin-right: 10px;
		height: 2px;
 
	}
	@include breakpoint(medium) {
		margin-bottom: 0;
	}
}
.conten_image_big {
	background-size: cover;
	background-position: center;
	height: 150px;
	margin: 20px 8px 20px 0;
	@include breakpoint(medium) {
		margin: 40px 20px 80px 0;
		height: 440px;
	}
}
.content_image_big_update {
    margin: 0 0 80px 0;
    height: auto;
    width: 100%;
}
.conten_image_small {
	background-size: cover;
	background-position: center;
	height: 220px;
	margin: 20px 0 20px 0;
	transition: all ease .25s;
	&:hover {
		opacity: 0.6;
	}
	@include breakpoint(medium) {
		margin: 20px 20px 20px 0;
		height: 240px;
		}
	@include breakpoint(large) {
		margin: 0 20px 20px 0;
		height: 440px;
		}
}
.content_image_box {
    margin: 20px 0 0 0 ;
	width: 100%;
    height: 140px;
	background-size: cover;
	background-position: center;
	transition: all ease .25s;
	&:hover {
		opacity: 0.6;
	}
	@include breakpoint(medium) {
		 height: 260px;
		 margin: 0 ;
		 width: 90%;
		}
	@include breakpoint(large) {
		height: 260px;
		}
}
#extra_content_grey {
	background: $col_acpgrau;
	padding: 30px 0;
	margin-top: 20px;
	@include breakpoint(medium) {
		margin-top: 60px;
		padding: $padding_section_standard;
		}
	span {
		display: block;
		padding-left: 20px;
		padding-bottom: 20px;
		@include breakpoint(medium) {
		padding-left: 20px;
		}
		@include breakpoint(large) {
		padding-left: 55px;
		font-weight: bold;
		}
		
	}
	h4 {
		font-family: $body-font-family;
		font-size: 1.125rem;
	}
}
#extra_content_grey_02 {
	background: $col_acpgrau;
	padding: 30px 0;
	margin-top: 20px;
	@include breakpoint(medium) {
		margin-top: 60px;
		padding: $padding_section_standard;
		}
	.whitecard {
	background: white;
	padding: 60px 20px;
	margin: 20px;
	h3 {
		text-align: center;
		font-family: $body-font-family;
		font-size: 1rem;
		span {
			display: block;
			padding: 20px 0 0 0;
			font-size: 1.1rem;
		}
		span.big {
			font-size: 2.2rem;
		}
	}
  }
}
h1.intro-title {
	width: 100%;
	padding-left:40px;
	margin-top: 20px;
	margin-bottom: 20px;
	@include breakpoint(medium) {
		width: 80%;
		margin: 20px auto 80px auto;
		
		}
	span {
		display: block;
		font-size: 1.2rem;
	font-family: $body-font-family;}

}
h2.intro-title {
	width: 100%;
	
	margin-top: 20px;
	font-size: 1.35rem;
	margin-bottom: 20px;
	@include breakpoint(medium) {
		width: 70%;
		padding-left:40px;
		font-size: 2rem;
		margin-bottom: 40px;
		}
	
}
h2.intro-title_update {
    font-size: 1.75rem;
    font-weight: bold;
	padding: 0 0 15px 0;
    text-align: center;
	@include breakpoint(medium) {
			font-size: 2rem;
				padding:  0;
		}
	@include breakpoint(large) {
			font-size: 2.5rem;
		
		}
	
}

h2.intro-title-news {
	width: 100%;
	font-size: 1.35rem;
	margin-bottom: 20px;
	@include breakpoint(medium) {
		font-size: 1.65rem;
		margin-bottom: 40px;
		}
		@include breakpoint(large) {
		font-size: 2.5rem;
		margin-bottom: 40px;
		}
	
}
h5.subtitle_news {
	font-size: 1.05rem;
}

span.intro-subtitle {
	&:before {
		content: "";
		width: 30px;
		position: relative;
		display: inline-block;
		border-bottom: 1px solid #707070;
		top: -4px;
		margin-right: 10px;
		height: 2px;
	}
	margin-top: 30px;
	display: block;
	@include breakpoint(medium) {
		margin-top: 0;
		}
}
span.intro-subtitle_update {
	margin-bottom: 30px;
	display: block;
	text-align: center;
	font-size: 1.1rem;
	font-weight: 500;
	color: #051D36;
	@include breakpoint(medium) {
		padding: 0 10%;
		font-size: 1.25rem;
		}
	@include breakpoint(large) {
		margin-bottom: 0;
		padding: 0 20%;
		font-size: 1.4rem;
		}
}
span.content-subtitle {
	display: block;
	padding: 20px 0 20px 0;
 
	@include breakpoint(medium) {
		padding: 0 60px 0 0;
		border-top: none;
		}
	@include breakpoint(large) {
		padding: 0 60px 0 25px;
		}
}
.content-width {
 
	@include breakpoint(large) {
		width: 80%;
		}
	}
.paddimage_block {
	margin: 20px 0;
	@include breakpoint(large) {
		margin: 120px 0 60px 0;
		}
}	
.content-width-box {
	border-top: 1px solid #d2d2d2;
	padding-top: 30px;
	p {
		width: 100%;
		@include breakpoint(small only) {
		font-size: 0.85rem;
		}
		@include breakpoint(large) {
		width: 80%;
		font-size: 0.95rem;
		}
	}
	h3 {
		font-family: $body-font-family;
		font-size: 1.35rem;
		padding-bottom: 10px;
	}
	@include breakpoint(medium) {
		padding-bottom: 30px;
		}
	@include breakpoint(large) {
		width: 80%;
		}
	}
/*** FAQ ***/
#faq {
	margin-top: 15px;
	padding: 40px 10px;
	background: $col_acpgrau;
	@include breakpoint(medium) {
		margin-top: 40px;
		padding: 80px 10px;
		}
	.accordion {
     padding-left: 0;
	}
	li.accordion-item {
		border: none;
		-webkit-box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.76);
-moz-box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.76);
box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.76);
		background: white;
		padding: 20px;
		margin-bottom: 10px;
	}
	.accordion-title {
    padding: 0 3rem 0 1rem;
    line-height: 1.3;
	font-size: 1.125rem;
	border: 0;
	@include breakpoint(medium) {
			font-size: 1.3rem;
		}
		&:hover {
			background: white;
		}
	}
	.accordion-content {
    display: none;
    padding: 1rem;
    border: 0;
    border-bottom: 0;
    background-color: white;
    color: #0a0a0a;
	}
	.accordion-title:hover, .accordion-title:focus {
    background-color: white;
	}
}

#faq_update {
	padding: 40px 10px;
	background: $col_acpgrau; 
	@include breakpoint(medium) {
		padding: 80px 10px;
		}
	.accordion {
     padding-left: 0;
	}
	li.accordion-item {
		border: none;
		-webkit-box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.76);
-moz-box-shadow: 10px 10px 10px -13px rgba(0,0,0,0.76);
box-shadow: 10px 10px 10px -13px rgba(0,0,0,0.76);
		background: white;
		padding: 12px;
		margin-bottom: 10px;
	}
	.accordion-title {
    padding: 0 3rem 0 1rem;
    line-height: 1.3;
	font-size: 1rem;
	border: 0;
	@include breakpoint(medium) {
			font-size: 1.15rem;
		}
		&:hover {
			background: white;
		}
	}
	.accordion-content {
    display: none;
    padding: 1rem;
    border: 0;
    border-bottom: 0;
    background-color: white;
    color: #0a0a0a;
	}
	.accordion-title:hover, .accordion-title:focus {
    background-color: white;
	}
}


/**** Standorte ***/
#standorte_update {
	background: $col_acpgrau;
	padding: 40px 10px;
	h3.spez {
		font-weight: bold;
		margin-bottom: 0;
		margin-top: 10px;
		
	}
	.card_wrapper_standort {
		background: white;
		margin: 20px 0;
		width: 100%;
		-webkit-box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.36);
-moz-box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.36);
box-shadow: 10px 10px 20px -13px rgba(0,0,0,0.36);
		.standort_left_content{
			width: 100%;
			float: left;
			padding: 40px;
			@include breakpoint(large) {
				width: 50%;
				padding: 70px;
				}
			 
			 .small {
				font-size: .9rem;
			 }
		}
		.standort_right_image {
			width: 100%;
			float: right;
			background: url('/wp-content/uploads/2019/09/DJI_0029-Medium.jpg') no-repeat;
			background-size: cover;
			background-position: center right;
			min-height: 260px;
			@include breakpoint(large) {
				width: 50%;
				height: 100%;
				}
			}
			.standort_right_image2 {
			width: 100%;
			float: right;
			background: url('/wp-content/uploads/2019/09/DJI_0558-HDR-Medium.jpg') no-repeat;
			background-size: cover;
			background-position: center right;
			min-height: 260px;
			@include breakpoint(large) {
				width: 50%;
				height: 100%;
				}
			}
		
	}
}

/*** Ansprechpartner ***/
#ansprechpartner_update {
	h3 {
		color:white;
		margin: 20px 0 30px 0;
		text-align: center;
	}
	background: #274b73; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #274b73 17%, #0a2c51 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  #274b73 17%,#0a2c51 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  #274b73 17%,#0a2c51 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#274b73', endColorstr='#0a2c51',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
padding: 40px 20px 60px 20px;

.tabs {
    margin: 0;
    border: none ; 
    background: none;

}
.tabs-title>a {
     padding: 1.25rem 1rem;
    font-size: 1.125rem;
    line-height: 1;
	font-family: $header-font-family;
}
.tabs-title>a:hover {
    background: none;
    color: white;
	opacity: .7;
}
.tabs-title {
 	border-bottom: 1px solid #ffffff3d;
	span {
		float: right;
	}
}
.tabs-title>a:focus, .tabs-title>a[aria-selected=true] {
    background: none;
    color: #fff;
	font-weight: bold;
}

}

.box_ansprech {
	
	width: 100%;
	margin: 0 0 20px 0;
	float: left;

	@include breakpoint(medium) {
			width: 49%;
			margin: 0 0 20px 1%;
			float: left;
					}
	@include breakpoint(large) {
			width: 32.3%;
			margin: 0 0 20px 1%;
			float: left;
					}
	.ansprech_bg {
			height:220px;
			background-size: cover;
			background-position: top center;
			@include breakpoint(medium) {
				height:300px;
			}
		}
	.ansprech_txt {
		padding: 20px 10px 0 10px ;
		min-height: 50px;
		color:  white;
	}
	h5 	{
		line-height: 1.5;
		span.spez {
			color:  white;
		}
		span {						 
			font-size: .8rem;
			display: block;
			font-weight: 300;
			letter-spacing: 1.2px;
			include breakpoint(medium) {
			font-size: .9rem;
			letter-spacing: 1.9px;
			}
		 a	 {
				font-size: 1rem;
				color:  #00e0ed;
				margin-top: 10px;
				display: inline-block;
				padding: 4px 15px;
				border: 2px solid  #00e0ed;
				transition: all ease .25s;
				&:hover {
					color: white;
					border: 2px solid  white;
					}
			}
			}
	
		}	
}
	
/*** Buttons **/
.close-button {
	padding: 8px 15px !important;
	background: $col_acpblau !important;
	font-size: 1.8em !important;
}
.btn_grey_light {
	background: $col_acpgrau;
	cursor: pointer;
	transition: all ease .4s;
	border-radius: 50px;
	text-align: center;

	margin: 20px 0 20px 0;
	display: inline-block;
	font-size: 0.9rem;
		&:hover {
			color: white;
			@include linear-gradient(50deg,   #00ACEA 0%,  #00EDD1 100%);
			}
	@include breakpoint(large) {
					margin: 50px 0 0 0;
		}
		a {
			color: black;
				display: inline-block;
				padding: 5px 25px;
				&:hover {
			color: white;
			 
			}
		}
}
.btn_blue_outline-small {
	border: 2px solid $col_acpblau;
	cursor: pointer;
	transition: all ease .4s;
	display: block;
    width: 100%;
    margin-top: 11px;
	font-size: 0.9rem;
	transition: all ease .25s;
	@include breakpoint(medium) {
					width: 340px;
		}
		&:hover {
			color: white;
			background: $col_acpblau;
			}
 
		a {
			color: $col_acpblau;
			text-transform: uppercase;
			font-weight: bold;
				padding: 5px 25px;
					display: inline-block;
				&:hover {
				color: white;
			 
			}
		}
}
.btn_blue_outline {
	border: 2px solid $col_acpblau;
	cursor: pointer;
	transition: all ease .4s;
	text-align: center;
	margin:  20px 0 0 0;
	display: inline-block;
	font-size: 0.9rem;
	transition: all ease .25s;
		&:hover {
			color: white;
			background: $col_acpblau;
			}
 
		a {
			color: $col_acpblau;
			text-transform: uppercase;
			font-weight: bold;
				padding: 5px 25px;
					display: inline-block;
				&:hover {
				color: white;
			 
			}
		}
}
.btn_grey_light_news {
	background: $col_acpgrau;
	cursor: pointer;
	transition: all ease .4s;
	border-radius: 50px;
	text-align: center;

	margin:  10px 0 20px 0;
	display: inline-block;
	font-size: 0.9rem;
		&:hover {
			color: white;
			background: $col_acpgrau_medium;
			}
	@include breakpoint(large) {
					margin:  0 0 10px 0;
		}
		a {
			color: black;
				padding: 5px 25px;
					display: inline-block;
				&:hover {
			color: white;
			 
			}
		}
}
.mega-btn_grey_light {

	background: rgb(0,172,234) !important; /* Old browsers */
background: -moz-linear-gradient(left, rgba(0,172,234,1) 0%, rgba(0,237,209,1) 100%) !important; /* FF3.6-15 */
background: -webkit-linear-gradient(left, rgba(0,172,234,1) 0%,rgba(0,237,209,1) 100%) !important; /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(0,172,234,1) 0%,rgba(0,237,209,1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00acea', endColorstr='#00edd1',GradientType=1 ) !important; /* IE6-9 */
	cursor: pointer;
	transition: all ease .4s;
	border-radius: 50px !important;
	text-align: center;
	padding: 2px 10px !important;
	
	margin: 0 0 0 30px !important;
	display: inline-block !important;
	height: 30px !important;
		&:hover {
				background: $col_acpgrau !important;
			}
			a  {
				font-size: 0.9rem !important;
				color: white !important;
				margin-top: -6px !important;
				padding: 0 10px !important;
				&:hover {
				background: none !important;
				color: black !important;
			}
			}
}
 
.btn_grey_medium {
	background: $col_acpgrau_medium;
	cursor: pointer;
	transition: all ease .4s;
	border-radius: 50px;
	text-align: center;
	padding: 5px 25px;
	margin: 50px 0 0 0;
	display: inline-block;
	font-size: 0.9rem;
	color: white;
		&:hover {
			color: white;
			@include linear-gradient(50deg,   #00ACEA 0%,  #00EDD1 100%);
			}
				a {
			color: black;
				&:hover {
			color: white;
			 
			}
		}
}
.btn_news {
	background: $col_acpblau;
	margin: 10px 0 0 0;
	padding: 10px 25px;
	display: inline-block;
	font-size: 0.9rem;
	color: white;
	transition: all ease .25s;
		&:hover {
			color: #3A3A3A;
			background: #00e0ed;
			}
	@include breakpoint(medium) {
		margin: 50px 0 0 0;
	}
		a {
			color: black;
				&:hover {
			color: white;
			 
			}
		}
	
}
#attachment_01, #attachment_02, #attachment_03 {
	text-align: center;
	img {
		transition: all ease .25s;
	}
	@include breakpoint(small only) {
		width: 33% !important;
		padding-right: 0 !important;
		margin-bottom: 20px;
		margin-top: 10px;
		img {
			width: 90px;
		}
	}
	&:hover {
		img {
			width: 85%;
		}
	}
}
.btn_search_small {
	color: white;
	background: $col_acpblau;
	margin: 0 5px 0 0;
	padding: 4px 12px;
	display: inline-block;
    bottom: 15px;
    left: 15px;
	font-size: 0.9rem;
	color: white;
	 
	@include breakpoint(medium) {
	 
	}
	
}
.btn_news_small {
	color: white;
	background: $col_acpblau;
	margin: 0 5px 0 0;
	padding: 4px 12px;
	display: inline-block;
	    position: absolute;
    bottom: 15px;
    left: 15px;
	font-size: 0.9rem;
	color: white;
	 
	@include breakpoint(medium) {
	 
	}
	
}
.btn_news_small_block {
	color: white;
	@include linear-gradient(50deg,   #00ACEA 0%,  #00EDD1 100%);
	margin: 0 0 20px 0;
	padding: 4px 12px;
	display: inline-block;
	font-size: 0.9rem;
	color: white;
	 
	@include breakpoint(medium) {
	 
	}
	
}
#more_wrapper {
	padding: 50px 10px 20px 10px;
	@include breakpoint(medium) {
					padding: 60px 0;
		}
	a {
		color: #292929;
	}
	h3 {
		font-family: $body-font-family;
		font-weight: bold;
		margin-top: 10px;
		padding: 0 ;
		font-size: 1.125rem;
		@include breakpoint(large) {
		padding: 0 40px 0 55px;
		margin-top: 20px;
		font-size: 1.5rem;
	}
	}
	h4 {
		padding: 10px 40px 0 40px;
	}
	span {
		&:before {
		content: "";
		width: 30px;
		position: relative;
		display: inline-block;
		border-bottom: 1px solid #707070;
		top: -4px;
		margin-right: 10px;
		height: 2px;
 
	}
	}
}

/******* Kontaktform ******/
#kontaktform_wrapper {
		.margright {
			padding-right: 10px;
		}
		.grey_bg_left {
			background: url('/wp-content/uploads/2019/08/bg_grey2.jpg') no-repeat;
			background-size: cover;
			background-position: bottom right;
			padding: 100px 0 0 20px;
					@include breakpoint(large) {
						padding: 100px 0 0 20vw;
					}
			.txt_grey_wrapper {
				padding-left: 10px;
					@include breakpoint(large) {
						padding-top: 120px;
						padding-left: 0;
						left: -54%;
						position: relative;
						float: right;
					}
			}
			h4 {
				font-size: 2rem;
				font-weight:bold;
				max-width: 420px;
					@include breakpoint(large) {
						font-size: 3rem;
					}
			}
			p {
				font-family: $header-font-family;
				font-size: 1.35rem;
				max-width: 420px;
			}
		}
		.image_bg_right {
			background: url('/wp-content/uploads/2019/08/DSC02714-Medium.jpg') no-repeat;
			background-size: cover;
	
		}
		.image_bg_right-2 {
			background: url('/wp-content/uploads/2019/03/DSC02554-2-Medium.jpg') no-repeat;
			background-size: cover;
	
		}
		.kontaktform_right {
			    position: relative;
				left: -45%;
				padding: 30px;
				background: white;
				max-width: 560px;
				margin: 40px 0;
		}
			.kontaktform_mobile {
				position: relative;
				padding: 30px;
				background: #fff;
				margin: 40px 30px 40px 0;
		}

	
}

/******** Kontakt *******/
.kontakt_wrapper {
	h2 {
		a {
			color:#292929;
			margin: .75rem 0 .25rem 0;
		    font-size: 1.45rem;
				@include breakpoint(medium) {
						 font-size: 1.35rem;
					}
					@include breakpoint(large) {
						 font-size: 2rem;
					}
		}
		span {
			float: right;
			    margin-top: 6px;
		}
	}
	h4 {
		font-family: $body-font-family;
		margin-top: 50px;
	}
	hr {
    margin: .25rem auto !important;
 }
}

/****** Impressionen Slider START *****/
#impressionen_slider {
			background-color: $col_acpgrau;
			padding: 40px 0 40px 0;
			@include breakpoint(medium) {
				padding: 100px 0 60px 0;
			}
			@include breakpoint(large) {
				padding: 110px 0  80px 0;
			}
			.description_box {
				background: white;
				padding: 20px;
				font-family: $header-font-family;
				text-align: center;
				
			}
			h4 {
			font-size: 1.5rem;
			text-align: center;
			top: -0;
			position: relative;
			font-weight: bold;
			@include breakpoint(medium) {
				top: -20px;
				font-size: 2rem;
				text-align: left;
			}
			}
}
/****** Impressionen Slider ENDE *****/

/******* Vorteile ******/
#vorteile_wrapper {
background: #274b73; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #274b73 17%, #0a2c51 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  #274b73 17%,#0a2c51 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  #274b73 17%,#0a2c51 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#274b73', endColorstr='#0a2c51',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
padding: 40px 20px 60px 20px;
@include breakpoint(large) {
			padding: 80px 20px 60px 20px;
		}
h3 {
	font-size: 2.8rem;
	font-weight: bold;
	color: white;
}
p {
	font-size: 1.2rem;
	color: white;
}
h4 {
	color: white;
}
hr {
	opacity: .3;
}
a {
	color: #00e0ed;
	transition: all ease .25s;
	&:hover {
		opacity: .7;
	}
}
.spez_01 {
	padding-right: 30px;
}
.spez_02 {
	padding: 30px 0;
	@include breakpoint(large) {
		padding:  0;
		}
	 li {
		 font-size: 1.4rem;
		 margin-bottom: 20px;
		 padding: 0 60px;
		 i {
			 position: absolute;
			margin-left: -75px;
			  img { 
				width: 40px;
				}
		 }
	 }
}
.btn_outline_more {
	a {
	display: inline-block;
    border: 1px solid #fff;
    padding: 6px 30px;
    color: #fff;
    margin: 5px 0 45px 0;
    font-size: .9rem;
	@include breakpoint(large) {
			 margin: 10px 0 25px 0;
		}
	}
}
 ul {
	 li {
		 list-style: none;
		 color: white;
		 font-family: $header-font-family;
		 font-weight: bold;
		 font-size: 1.1rem;
		 margin-bottom: 20px;
		 padding: 0 15px;
		 i {
			 position: absolute;
			margin-left: -35px;
			  img { 
				width: 20px;
				}
		 }
	 }
 }
}
/****** KONTAKT ******/
#standorte {
	margin: 20px auto;
	padding: 0 2%;
	@include breakpoint(large) {
			margin: 60px auto;
			padding: 0 10%;
		}
	.bg_standort {
		position: relative;
		background: url('/kunden/wpacp/wp-content/uploads/2019/01/business_bg.jpg') no-repeat;
		background-size: cover;
		backgroun-position: center center;
		width: 100%;
		height: 420px;
		margin-bottom: 80px;
		.infobox {
			background: $col_acpblau;
			padding: 30px 40px;
			color: white;
			position: absolute;
			width: 400px;
			right: 40px;
			bottom: -40px;
			height: auto;
		}
		.infobox_left {
			background: $col_acpblau;
			padding: 30px 40px;
			color: white;
			position: absolute;
			width: 400px;
			left: 40px;
			bottom: -40px;
			height: auto;
		}
	}
}
/****** HACKS *******/
._brlbs-content-blocker {
	  padding-bottom: 0 !important;
}
 .embed-container { 
        position: relative; 
        padding-bottom: 56.25%;
        overflow: hidden;
        max-width: 100%;
        height: auto;
    } 

    .embed-container iframe,
    .embed-container object,
    .embed-container embed { 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
.main-grid {
@include breakpoint(large) {
	margin-top: 3.5rem;
		}
@include breakpoint(xlarge only) {
	margin-top: 3.5rem;
		}
}
#cookie-law-info-bar {

    font-size: 10pt;
    text-align: left;
    font-weight: 300;
    line-height: 1.35;

}
#mega-menu-wrap-top-bar-r #mega-menu-top-bar-r li.mega-menu-item-has-children>a.mega-menu-link>span.mega-indicator:after {
    margin: 0 0 0 2px !important;

}
#mega-menu-wrap-top-bar-r #mega-menu-top-bar-r>li.mega-menu-item>a.mega-menu-link {
     padding: 0 10px !important;
     font-size: 16px !important;
}
.grecaptcha-badge {
display: none;
}
img.alignleft  {

	@include breakpoint(small only) {
	width: 100%;
    padding-bottom: 25px;
		}
		@include breakpoint(medium ) {
    padding-bottom: 15px;
	padding-right: 25px;
		}
}
img.alignright  {
	@include breakpoint(small only) {
	width: 100%;
    padding-bottom: 25px;
		}
	@include breakpoint(medium ) {
      padding-bottom: 15px;
	padding-left: 25px;
		}
}
.paddnavsingle {
	padding: 0 20px;
}
.main-grid .sidebar {
    padding-left: 10px;
}
.menu.nested {
    margin-right: 0;
    margin-left: .5rem;
	a {
		padding: 8px !important;
		text-transform: none !important;
		    line-height: 1.5 !important;
	}
}
.mobile-menu .menu .is-active>a, .mobile-off-canvas-menu .menu .is-active>a {
	background: none !important;
	 font-weight: 600 !important;
}
.off-canvas>ul.menu a {
    color: #fff;
    font-weight: 300 !important;
    font-size: .9rem;
    letter-spacing: 0.3px !important;
    padding: 25px 15px;
    text-transform: uppercase;
}
.submenu-toggle {
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
    width: 40px;
    height: 40px;
}
.top-bar-right {
	a {
		font-family: $body-font-family !important;
		font-weight: 500 !important;
		color: #292929;
	}
}
#mega-menu-top-bar-r {
	@include breakpoint(small only) {
					display: none !important;
		}
}
li.mega-menu-item-type-widget.widget_text {
	padding: 0  15px 5px 15px !important ;
	
}
li.mega-menu-item-has-children {
	.mega-sub-menu {
		.widget_media_image {
			padding: 15px 15px 0 15px !important;
		}
a.mega-menu-link {
		font-size: .9rem !important;
		text-align: left !important;
		line-height: 1.3 !important;
		text-transform: uppercase !important;
		padding: 10px 15px !important;
	}
	}
}
#mega-menu-mobile-nav {
	background: $col_acpblau !important;
	li.mega-menu-item.mega-current-page-ancestor a.mega-menu-link {
 
	}
}
.off-canvas {
	background: $col_acpblau;
}
	
.title-bar .menu-icon {
     background: none;
}
.off-canvas>ul.menu a {
	color: white;
    font-weight: 600;
    font-size: .9rem;
    letter-spacing: 2px;
    padding: 25px 15px;
    text-transform: uppercase;
}
.menu .is-active>a {
    background: none; 
}
	
.title-bar button {
		&:hover {
		color: $col_acpgrau;
		background: none;
	}
}
.title-bar .menu-icon {
 
    float: right;
}
.menu-icon:after {
    position: absolute;
    top: 0;
	transition: all ease .4s;
    left: 0;
    display: block;
    width: 30px !important;
    height: 2px;
    background: black;
    box-shadow: 0 7px 0 black, 0 14px 0 black;
    content: '';
}
.accordion {
	background: $col_acpgrau;
	padding-left: 20px;
}
.accordion-content {
	background: $col_acpgrau;
	padding-left: 0;
}
.accordion-title {
	font-size: 1.125rem;
	font-family: $body-font-family;
	font-weight: normal;
	padding-left: 0;
	color: #292929;
	@include breakpoint(medium) {
		font-size: 1.5rem;
		}
}
li.accordion-item {
	border-top: 1px solid #d2d2d2;
	&:last-child {
 
	}
}
.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
    background: black;
    color: white;
}

.tabs-title > a {
    display: block;
    padding: 1.25rem 1rem;
    font-size: 0.75rem;
    line-height: 1;
    color: $col_acpgrau;
}

.tabs-content {
    border: none; 
    border-top: 0; 
    background: none; 
    color: #0a0a0a;
    transition: all 0.5s ease;
}

.tabs-panel {
    display: none;
    padding: 1rem 0;
	 @include breakpoint(large) {
			padding: 0 1rem;
		  }
}

#owl_landing.owl-theme .owl-nav {
    position: absolute;
 	right: 0;
	bottom: 0;	
	margin-top: 0 !important;
	 @include breakpoint(medium) {
 
	 }
     text-align: center;
    -webkit-tap-highlight-color: transparent;
}
#owl_landing.owl-theme .owl-nav [class*='owl-'] {
	transition: all ease .45s;
	height: 60px;
	width: 60px;
    color: white !important;
    font-size: 14px;
    margin: 0  !important;
    padding: 20px 10px 0 10px !important;
    background: $col_acpblau;
    border-radius: 0 !important;
			&:hover {
			@include linear-gradient(50deg,   #00ACEA 0%,  #00EDD1 100%);
			}
}

#x_owl_case.owl-theme .owl-nav {
    position: absolute;
 	right: 0;
	margin-top: 0 !important;
	@include breakpoint(small only) {
		bottom: -50px;
	 }
	 @include breakpoint(medium) {
		top: -100px;
	 }
     text-align: center;
    -webkit-tap-highlight-color: transparent;
}
#x_owl_case.owl-theme .owl-nav [class*='owl-'] {
	transition: all ease .45s;
	height: 42px;
    width: 50px;
    color: white !important;
    font-size: 14px;
    margin: 0 0 0 10px !important;
    padding: 10px !important;
    background: #051D36;
    border-radius: 0 !important;
	 @include breakpoint(medium) {
		 height: 55px;
		  padding: 13px !important;
    width: 60px;
	 }	
			&:hover {
			background: $col_acpblau;
			}
	img {
		@include breakpoint(small only) {
		width: 8px;
	 }	
	}		
}
#x_owl_logos {
	.item img {
		transition: all 0.2s;
		  -webkit-transition: all 0.2s;
		  -moz-transition: all 0.2s;
		 filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		&:hover {
			  filter: grayscale(0%);
				-webkit-filter: grayscale(0%);
				-moz-filter: grayscale(0%);
		}
	}
}

#owl_impressionen.owl-theme .owl-nav {
    
	@include breakpoint(medium) {
	position: absolute;
    text-align: center;
    top: -70px;
    right: 0;
	margin-top: 0;
	}
	}
#owl_impressionen .owl-item img {
	    border: 10px solid white;
 
		}
#owl_impressionen.owl-theme .owl-nav [class*='owl-'] {
	transition: all ease .45s;
	height: 45px;
	width: 60px;
    color: white !important;
    font-size: 14px;
    margin: 0 0 0 5px !important;
    padding: 12px 10px 0 !important;
    background: $col_acpblau;
    border-radius: 0 !important;
			&:hover {
			@include linear-gradient(50deg,   #00ACEA 0%,  #00EDD1 100%);
			}
}
 
.owl-theme .owl-dots .owl-dot span {
    width: 20px  !important;
    height: 4px  !important;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
	padding: 0 !important;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 0  !important; 

}
.owl-theme .owl-nav.disabled + .owl-dots {

}
#owl_kundenstimmen .owl-dots .owl-dot span {
    width: 10px  !important;
    height: 10px  !important;
    margin: 5px 7px;
    background: none;
    display: block;
	padding: 0 !important;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 130px !important;
    border: 1px solid white;

}
#owl_kundenstimmen .owl-dots .owl-dot.active span, #owl_kundenstimmen .owl-dots .owl-dot:hover span {
    background: white;
}
span.wpcf7-list-item {
    display: inline-block;
    margin: 0 0 0 0;
	padding-right: 10px;
}
[type=file], [type=checkbox], [type=radio] {
    margin: 0;
}
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    margin: 0 0 1rem;
    padding: 0.5rem;
    border: none;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0;
    background-color: #f7f7f7;
	box-shadow: none; 
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #0a0a0a;
     -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
textarea[rows] {
    height: 150px;
}
[type='submit'], [type='button'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
	transition: all ease .5s;
    padding: 10px 20px;
	background: black;
	color: white;
    border: none;
	cursor: pointer;
	&:hover {
		color: white;
		background:  $col_acpblau;
	}
}
input:disabled, input[readonly], textarea:disabled, textarea[readonly] {
   
}

label {
   
}

@media screen and (max-width: 39.9375em) {
.reveal {
    top: 20% !important;
    left: 0;
     height: auto !important;
    min-height: auto !important;
    margin-left: 0;
    border: 0;
    border-radius: 0;
}
}



.footer {
  background: #0A2C51;
  color: #BCBCBC;
  text-align: left;
  padding: 0 20px;
  @include breakpoint(medium) {
	text-align: left;
	 padding: 0 ;
  }
  .rechtl {
		a {
			padding-right: 20px;
			@include breakpoint(medium) 
			{
				padding-left: 20px;
				float: right;
			}
		}
	}
  a {
  color: #BCBCBC;
	&:hover {
		color: white;
	}
  }
}
.footer-container {
  @include xy-grid-container;
  margin-top: rem-calc(20);

}
.footer-container-update {
  @include xy-grid-container;
  margin-top: 0;
}

.footer-grid {
  @include xy-grid;
  padding: rem-calc(60) 0;

  section {
    @include xy-cell();
    @include breakpoint(large) {
      @include xy-cell(auto);
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}


.footer-update {
  background: #051D36;
  color: #E8E8E8;
  text-align: left;
  padding: 0 20px;
  @include breakpoint(medium) {
	text-align: left;
	 padding: 0 ;
  }
  strong {
  color: white !important;
  }
  .rechtl {
  	text-align: left;
	margin-top: 30px;
	i {
		font-size: 1.3rem;
		padding-right: 10px;
		@include breakpoint(large) {
		padding-right: 0;
		padding-left: 10px;
			}
	}
	@include breakpoint(large) 
			{
				text-align: right;
				margin-top: 0;
			}
		a {
			display: inline-block;
			padding-right: 20px;
			@include breakpoint(large) 
			{
				padding-right: 0;
				padding-left: 20px;
			
			}
		}
	}
  a {
  color: #BCBCBC;
	&:hover {
		color: white;
	}
  }
}
.featured-hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: rem-calc(280);

  @include breakpoint(medium) {
    height: rem-calc(320);
  }

  @include breakpoint(large) {
    height: rem-calc(460);
  }
}

.x_featured-hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: rem-calc(340);

  @include breakpoint(medium) {
    height: rem-calc(320);
  }

  @include breakpoint(large) {
    height: rem-calc(490);
	}
}

.featured-hero-landing {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: rem-calc(420);
    margin-bottom: 40px;
  @include breakpoint(medium) {
    height: rem-calc(550);
	    margin-bottom: 0;
  }

  @include breakpoint(large) {
    height: rem-calc(440);
  }
}

.featured-hero-cta {
  background-size: cover;
  background-image: url('https://www.acp-systems.com/wp-content/uploads/2019/09/header_03-1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  height: rem-calc(360);
   @include breakpoint(medium) {
    height: rem-calc(550);
	    margin-bottom: 0;
  }

  @include breakpoint(large) {
    height: rem-calc(440);
  }
}


